import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import StyledButton from "../shared/StyledButton";
import StyledPromoBanner from "./StyledPromoBanner";
import ArrowRight from "../../globals/icons/ArrowRight";
import { isBrowser } from "../../utils/constants";
import {
  StyledSubTitle2,
  StyledDescTag,
  StyledTitle4,
  StyledDesc1,
  StyledDesc2,
} from "../shared/StyledTypography";
import { COLORS } from "../../globals/designSystem";
import { mq } from "../../globals/utils";
import { Image } from "../../globals/UIKit";

// import traingleArrow from "./images/traingle-arrow.svg";
import star from "./images/star.svg";
// import promoImg from "./images/promo-img.png";
import promoImgMobile from "./images/promo-img-mobile.png";

const featureState = [
  // {
  //   title: "Hasura Basics - v2",
  //   desc: "New to Hasura? This is a 30-minute hands-on tutorial to get you up and running with Hasura.",
  //   btnText: "Start Course",
  //   btnLink: "https://hasura.io/learn/graphql/hasura/introduction/",
  //   bgClass: "skyBg",
  //   arrowColor: "grey_0",
  // },
  // {
  //   title: "Hasura Basics - v3",
  //   desc: "Hasura v3 is the latest version of Hasura and is a complete rewrite of the Hasura architecture. It's faster, more powerful, and more flexible than ever before.",
  //   btnText: "Start Course",
  //   btnLink: `https://hasura.io/learn/graphql/hasura-v3/introduction/`,
  //   bgClass: "skyBg",
  //   arrowColor: "grey_0",
  // },
  {
    title: "GraphQL Basics",
    desc: "New to GraphQL? Learn the fundamentals of GraphQL and what makes it especially suitable for modern applications, like its realtime capabilities!",
    btnText: "Start Course",
    btnLink: "https://hasura.io/learn/graphql/intro-graphql/introduction/",
    bgClass: "greyBg",
    arrowColor: "grey_0",
  },
];

const StyledHeroBannerWrapper = styled.section`
  .breadCrumb {
    display: flex;
    align-items: center;
    padding-bottom: 32px;
    margin-top: -4px;
  }
  .wd578 {
    width: 578px;
  }
  ${mq.below.md} {
    .wd578 {
      width: 100%;
    }
  }
`;

const StyledFeatureWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  .featureList {
    padding: 32px;
    border-radius: 8px;
    display: grid;
    .alignSlefStart {
      align-self: flex-start;
    }
    .buttonWrapper {
      align-self: flex-end;
      button {
        .arrow {
          display: flex;
        }
        &:hover {
          .arrow {
            svg {
              fill: ${COLORS.grey_80};
            }
          }
        }
      }
    }
  }
  .skyBg {
    background-color: ${COLORS.sky_80};
  }
  .greyBg {
    background-color: ${COLORS.grey_90};
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
  }
`;

const StyledPromoBannerImg = styled(StyledPromoBanner)`
  background-image: ${props =>
    props.isVisible
      ? "url(https://res.cloudinary.com/dh8fp23nd/image/upload/v1672906142/main-web/promo-img-fda9762bf4896b48b9a99031a19e2d4b_qh2wgz.png)"
      : ""};

  margin: 40px 0;
  ul {
    li {
      img {
        margin-right: 8px !important;
      }
    }
  }
  ${mq.below.md} {
    background-image: none;
  }
`;

const HeroBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  const learnIllusRef = useRef();

  useEffect(() => {
    if (isBrowser) {
      if (!learnIllusRef?.current) {
        return;
      }

      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });

      observer.observe(learnIllusRef.current);
    }
  }, [learnIllusRef]);

  const feature = featureState.map((fetureItem, index) => (
    <div key={index} className={"featureList " + [fetureItem.bgClass]}>
      <div className="alignSlefStart">
        <StyledSubTitle2 variant="grey_0" paddingBottom="pb8">
          {fetureItem.title}
        </StyledSubTitle2>
        <StyledDesc2 variant="grey_0" paddingBottom="pb16">
          {fetureItem.desc}
        </StyledDesc2>
      </div>
      <div className="buttonWrapper">
        <a href={fetureItem.btnLink}>
          <StyledButton variant="transparent_grey_0" size="smd">
            {fetureItem.btnText}
            <div className="arrow">
              <ArrowRight variant={fetureItem.arrowColor} size="xs" className="arrow" />
            </div>
          </StyledButton>
        </a>
      </div>
    </div>
  ));

  return (
    <StyledHeroBannerWrapper>
      <div className="breadCrumb">
        <StyledDescTag>Hasura Learn</StyledDescTag>
      </div>
      <StyledTitle4 className="wd578" paddingBottom="pb8" as="h1">
        Fullstack GraphQL Tutorials for the busy developer
      </StyledTitle4>
      <StyledDesc1 className="wd578">
        The most extensive list of open source community maintained tutorials to get you up to speed
        with GraphQL, Hasura and Databases. Basics to production ready concepts.
      </StyledDesc1>
      <StyledPromoBannerImg isVisible={isVisible || false}>
        <div className="p40" ref={learnIllusRef}>
          <StyledSubTitle2 paddingBottom="pb16">
            The fastest way to get started with GraphQL
          </StyledSubTitle2>
          <StyledDesc2>
            <ul>
              <li>
                <Image className="leftIcon" src={star} alt="Arrow" />
                25+ courses
              </li>
              <li>
                <Image className="leftIcon" src={star} alt="Arrow" />
                Multilingual
              </li>
              <li>
                <Image className="leftIcon" src={star} alt="Arrow" />
                150+ contributors
              </li>
              <li>
                <Image className="leftIcon" src={star} alt="Arrow" />
                30 min - 2 hours
              </li>
            </ul>
          </StyledDesc2>
        </div>
        <div className="promoImgWrapper">
          <Image className="showMobile" src={promoImgMobile} alt="Promo" />
        </div>
      </StyledPromoBannerImg>
      <StyledFeatureWrapper>{feature}</StyledFeatureWrapper>
    </StyledHeroBannerWrapper>
  );
};

export default HeroBanner;
