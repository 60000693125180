import React, { useState, useEffect, useRef, useMemo } from "react";

import { Image } from "../../globals/UIKit";
import HeroBanner from "./HeroBanner";
import Tutorials from "./Tutorials";
import Contributor from "./Contributor";
import LearnFaq from "./LearnFaq";
import RecommendedResources from "./RecommendedResources";
import UseHasuraFree from "./UseHasuraFree";
import ChevronRight from "../../globals/icons/ChevronRight";
import NewWindowOpen from "../../globals/icons/NewWindowOpen";
import SideBarToggle from "../../globals/icons/SideBarToggle";
import Close from "../../globals/icons/Close";
import CopyWriterWithSidebar from "../common/CopyWriterWithSidebar";
import { HASURA_DOCS_LINK, isBrowser } from "../../utils/constants";

import {
  frontendTutorialState,
  backendTutorialState,
  fullstackTutorialState,
  databaseTutorialState,
  introGraphQLState,
} from "./learnState.js";
import {
  StyledMainWrapper,
  StyledToggleSideNavWrapper,
  StyledSideBarWrapper,
  StyledSideBarFooter,
  StyledLearnWrapper,
} from "../shared/CommonLearnWrapper";
import { StyledDesc3 } from "../shared/StyledTypography";
import { flexCenter, showTab, hideTab, translateXZero } from "../shared/CommonStyled";

// import Paperform from "../contactus/Paperform";

const sideBarState = [
  // {
  //   title: "Hasura v2 Tutorials · 4",
  //   listId: "hasura-tutorials-nav",
  //   id: "hasura-tutorials",
  //   isMainNavOpen: false,
  //   subNavItems: [
  //     {
  //       courseName: "Hasura Basics - v2",
  //       courseLink: "https://hasura.io/learn/graphql/hasura/introduction/",
  //     },
  //     {
  //       courseName: "Authorization Patterns with Hasura",
  //       courseLink: "https://hasura.io/learn/graphql/hasura-auth-slack/introduction/",
  //     },
  //     {
  //       courseName: "Authentication with Hasura",
  //       courseLink: "https://hasura.io/learn/graphql/hasura-authentication/introduction/",
  //     },
  //     {
  //       courseName: "Hasura Advanced",
  //       courseLink: "https://hasura.io/learn/graphql/hasura-advanced/introduction/",
  //     },
  //   ],
  // },
  // {
  //   title: "Hasura v3 Tutorials · 3",
  //   listId: "hasura-tutorials-v3-nav",
  //   id: "hasura-tutorials",
  //   isMainNavOpen: false,
  //   subNavItems: [
  //     {
  //       courseName: "Hasura Basics - v3",
  //       courseLink: "https://hasura.io/learn/graphql/hasura-v3/introduction/",
  //     },
  //     {
  //       courseName: `Hasura v3 Supergraph`,
  //       courseLink: `https://hasura.io/learn/graphql/hasura-v3-supergraph/introduction/`,
  //     },
  //     {
  //       courseName: `Hasura v3 TS Connector`,
  //       courseLink: `https://hasura.io/learn/graphql/hasura-v3-ts-connector/introduction/`,
  //     },
  //   ],
  // },
  {
    title: "Front-end Tutorials ∙ 15",
    listId: "front-end-tutorials-nav",
    id: "front-end-tutorials",
    isMainNavOpen: false,
    subNavItems: [
      {
        courseName: "GraphQL with React",
        courseLink: "https://hasura.io/learn/graphql/react/introduction/",
      },
      {
        courseName: "GraphQL with Vue",
        courseLink: "https://hasura.io/learn/graphql/vue/introduction/",
      },
      {
        courseName: "GraphQL with TypeScript",
        courseLink: "https://hasura.io/learn/graphql/typescript-react-apollo/introduction/",
      },
      {
        courseName: "GraphQL with React Native",
        courseLink: "https://hasura.io/learn/graphql/react-native/introduction/",
      },
      {
        courseName: "GraphQL with Angular",
        courseLink: "https://hasura.io/learn/graphql/angular-apollo/introduction/",
      },
      {
        courseName: "GraphQL with Flutter",
        courseLink: "https://hasura.io/learn/graphql/flutter-graphql/introduction/",
      },
      {
        courseName: "GraphQL with Android",
        courseLink: "https://hasura.io/learn/graphql/android/introduction/",
      },
      {
        courseName: "GraphQL with Elm",
        courseLink: "https://hasura.io/learn/graphql/elm-graphql/introduction/",
      },
      {
        courseName: "GraphQL with Unity",
        courseLink: "https://hasura.io/learn/graphql/unity/introduction/",
      },
      {
        courseName: "GraphQL with ReasonML",
        courseLink: "https://hasura.io/learn/graphql/reason-react-apollo/introduction/",
      },
      {
        courseName: "GraphQL with iOS",
        courseLink: "https://hasura.io/learn/graphql/ios/introduction/",
      },
      {
        courseName: "GraphQL with ReScript",
        courseLink: "https://hasura.io/learn/graphql/rescript-react-apollo/introduction/",
      },
      {
        courseName: "GraphQL with Svelte",
        courseLink: "https://hasura.io/learn/graphql/svelte-apollo/introduction/",
      },
      {
        courseName: "GraphQL with Remix",
        courseLink: "https://hasura.io/learn/graphql/remix-fullstack-firebase/introduction/",
      },
      {
        courseName: "GraphQL with VectorDBs",
        courseLink: "https://hasura.io/learn/graphql/vectordbs/introduction/",
      },
    ],
  },
  {
    title: "Full-stack Tutorials ∙ 2",
    listId: "full-stack-tutorials-nav",
    id: "fullstack-tutorials",
    isMainNavOpen: false,
    subNavItems: [
      {
        courseName: "GraphQL with NextJS",
        courseLink: "https://hasura.io/learn/graphql/nextjs-fullstack-serverless/introduction/",
      },
      {
        courseName: "RxDB Offline First Tutorial",
        courseLink: "https://hasura.io/learn/graphql/react-rxdb-offline-first/introduction/",
      },
    ],
  },
  {
    title: "Database Tutorials ∙ 3",
    listId: "database-tutorials-nav",
    id: "database-tutorials",
    isMainNavOpen: false,
    subNavItems: [
      {
        courseName: "PostgreSQL Tutorial",
        courseLink: "https://hasura.io/learn/database/postgresql/introduction/",
      },
      {
        courseName: "MySQL Tutorial",
        courseLink: "https://hasura.io/learn/database/mysql/introduction/",
      },
      {
        courseName: "Microsoft SQL Server",
        courseLink: "https://hasura.io/learn/database/microsoft-sql-server/introduction/",
      },
      // {
      //   courseName: "YugabyteDB Tutorial",
      //   courseLink: "https://hasura.io/learn/database/yugabyte/introduction/",
      // },
    ],
  },
  {
    title: "Intro to GraphQL",
    listId: "intro-to-graphql-nav",
    id: "intro-to-graphql",
    isMainNavOpen: false,
    subNavItems: [
      {
        courseName: "Introduction to GraphQL",
        courseLink: "https://hasura.io/learn/graphql/intro-graphql/introduction/",
      },
    ],
  },
];

const getDimensions = ele => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const scrollTo = ele => {
  ele.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

const LearnIndex = ({ location }) => {
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [isAliId, setIsAliId] = useState(false);
  const [isLocalSideBarSubscribe, setIsLocalSideBarSubscribe] = useState(false);

  const [windowScrollPosition, updateWindowScrollPosition] = useState(0);

  const onSubmitCB = () => {
    if (isBrowser) {
      window.localStorage.setItem("sideBarSubscribeConsent", "true");
    }
  };

  const getStripBannerActiveStatus = () => {
    if (location?.state?.isStripBannerClosed) {
      return false;
    }

    if (windowScrollPosition < 60) {
      return true;
    }

    if (windowScrollPosition > 60) {
      return false;
    }
  };

  const isStripBannerActiveInView = getStripBannerActiveStatus();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchAliId = searchParams.get("aliId");
    if (searchAliId || searchAliId === "") {
      setIsAliId(true);
    }

    if (isBrowser) {
      if ("localStorage" in window && window.localStorage && "getItem" in window.localStorage) {
        const sideBarSubscribeConsent = window.localStorage.getItem("sideBarSubscribeConsent");
        if (sideBarSubscribeConsent) {
          setIsLocalSideBarSubscribe(true);
        }
      }
    }
  }, [location.search]);

  const headerRef = useRef(null);
  const heroBannerRef = useRef(null);
  const hasuraTutorialRef = useRef(null);
  const frontendTutorialRef = useRef(null);
  const backendTutorialRef = useRef(null);
  const fullstackTutorialRef = useRef(null);
  const databaseTutorialRef = useRef(null);
  const introGraphQLRef = useRef(null);
  const learnFaqRef = useRef(null);
  const recommendedResourcesRef = useRef(null);

  // eslint-disable-next-line
  const sideBarSection = {
    "hero-banner": { section: "hero-banner", ref: heroBannerRef },
    "hasura-tutorials": { section: "hasura-tutorials", ref: hasuraTutorialRef },
    "front-end-tutorials": { section: "front-end-tutorials", ref: frontendTutorialRef },
    "fullstack-tutorials": { section: "fullstack-tutorials", ref: fullstackTutorialRef },
    "database-tutorials": { section: "database-tutorials", ref: databaseTutorialRef },
    "intro-to-graphql": { section: "intro-to-graphql", ref: introGraphQLRef },
    "learn-faq": { section: "learn-faq", ref: learnFaqRef },
    "recommended-resources": { section: "recommended-resources", ref: recommendedResourcesRef },
  };

  const [visibleSection, setVisibleSection] = useState();
  const [isSubNavShow, setIsSubNavShow] = useState(false);
  const [isLearn, setIsLearn] = useState(true);
  const [openSubNavIds, setOpenSubNavIds] = useState({});

  const showSubNav = id => {
    const newOpenSubNavIds = { ...openSubNavIds };
    if (typeof newOpenSubNavIds[id] === "undefined") {
      newOpenSubNavIds[id] = true;
    } else {
      newOpenSubNavIds[id] = !newOpenSubNavIds[id];
    }
    setOpenSubNavIds(newOpenSubNavIds);
  };

  const sectionRefs = useMemo(() => sideBarSection, [sideBarSection]);

  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(headerRef.current);
      const scrollPosition = window.scrollY + headerHeight;

      const currentScrollPosition = window.pageYOffset;

      updateWindowScrollPosition(currentScrollPosition);

      const selected = Object.values(sectionRefs).find(({ section, ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        } else {
          return null;
        }
      });

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSection, sectionRefs]);

  const sideNav = sideBarState.map(navItem => (
    <li
      id={navItem.listId}
      key={navItem.listId}
      role="presentation"
      onClick={() => {
        scrollTo(sectionRefs[navItem.id].ref.current);
        setIsSubNavShow(true);
      }}
    >
      <StyledDesc3 linkVariant="grey_100">
        <a
          href={`#${navItem.id}`}
          role="button"
          tabIndex="0"
          onClick={() => showSubNav(navItem.listId)}
          className={visibleSection === navItem.id ? "tabListActive" : ""}
        >
          <ChevronRight
            variant="grey_100"
            size="xs"
            className={openSubNavIds[navItem.listId] ? "rotateImg" : ""}
          />
          {navItem.title}
        </a>
      </StyledDesc3>
      <ul className="subNavWrapper" css={openSubNavIds[navItem.listId] ? showTab : hideTab}>
        {navItem.subNavItems.map((subNavItem, index) => (
          <li key={index}>
            <StyledDesc3 linkVariant="grey_100">
              <a href={subNavItem.courseLink}>
                <div className="circle"></div>
                {subNavItem.courseName}
              </a>
            </StyledDesc3>
          </li>
        ))}
      </ul>
    </li>
  ));

  return (
    <StyledMainWrapper>
      <div className={"learnMainWrapper" + (toggleSideBar ? " sideBarCollapse" : "")}>
        <StyledSideBarWrapper isStripBannerActiveInView={isStripBannerActiveInView}>
          <div
            className={"sidebarWrapper" + (toggleSideBar ? " sidebarWrapperCollapse" : "")}
            css={isSubNavShow ? translateXZero : null}
          >
            <div
              className={"mainSideBarToggle" + (toggleSideBar ? " mainSideBarTogglePos" : "")}
              role="presentation"
              onClick={() => {
                setToggleSideBar(!toggleSideBar);
              }}
            >
              <SideBarToggle variant="grey_100" size="xs" />
              {toggleSideBar ? (
                <div className="navigation">
                  <StyledDesc3 fontWeight="font_600">Navigation</StyledDesc3>
                </div>
              ) : null}
            </div>
            <div className="alignSelfStart" ref={headerRef}>
              {!toggleSideBar ? (
                <>
                  <StyledDesc3
                    className="mainNavHeader"
                    linkVariant="grey_100"
                    fontWeight="font_600"
                    css={flexCenter}
                  >
                    {/* eslint-disable-next-line */}
                    <a
                      className={visibleSection === "hero-banner" ? "tabListActive" : ""}
                      role="presentation"
                      onClick={() => {
                        setIsLearn(!isLearn);
                        scrollTo(sectionRefs["hero-banner"].ref.current);
                      }}
                    >
                      <ChevronRight
                        variant="grey_100"
                        size="xs"
                        className={isLearn ? "rotateImg" : ""}
                      />
                      Learn
                    </a>
                  </StyledDesc3>
                  <ul className="mainNavWrapper" css={isLearn ? showTab : hideTab}>
                    {sideNav}
                    <li>
                      <StyledDesc3 linkVariant="grey_100">
                        <a href="https://hasura.io/learn/graphql/backend-stack/introduction/">
                          <div className="circle"></div>
                          Backend Tutorials
                        </a>
                      </StyledDesc3>
                    </li>
                    <li
                      className={visibleSection === "learn-faq" ? "tabListActive" : ""}
                      role="presentation"
                      onClick={() => {
                        scrollTo(sectionRefs["learn-faq"].ref.current);
                      }}
                    >
                      <StyledDesc3 linkVariant="grey_100">
                        <a href="#learn-faq">
                          <div className="circle"></div>
                          FAQs
                        </a>
                      </StyledDesc3>
                    </li>
                    <li
                      className={visibleSection === "recommended-resources" ? "tabListActive" : ""}
                      role="presentation"
                      onClick={() => {
                        scrollTo(sectionRefs["recommended-resources"].ref.current);
                      }}
                    >
                      <StyledDesc3 linkVariant="grey_100">
                        <a href="#recommended-resources">
                          <div className="circle"></div>
                          Recommended Resources
                        </a>
                      </StyledDesc3>
                    </li>
                  </ul>
                </>
              ) : null}
            </div>
            {!toggleSideBar ? (
              <StyledSideBarFooter>
                <StyledDesc3
                  className="mainNavHeader"
                  linkVariant="grey_100"
                  fontWeight="font_600"
                  css={flexCenter}
                >
                  <a href={HASURA_DOCS_LINK} target="_blank" rel="noopener noreferrer">
                    <ChevronRight variant="grey_100" size="xs" />
                    Docs
                    <NewWindowOpen className="rightIcon" variant="grey_100" size="xs" />
                  </a>
                </StyledDesc3>
                <StyledDesc3
                  className="mainNavHeader"
                  linkVariant="grey_100"
                  fontWeight="font_600"
                  css={flexCenter}
                >
                  <a href="https://hasura.io/blog/" target="_blank" rel="noopener noreferrer">
                    <ChevronRight variant="grey_100" size="xs" />
                    Blog
                    <NewWindowOpen className="rightIcon" variant="grey_100" size="xs" />
                  </a>
                </StyledDesc3>
                <StyledDesc3
                  className="mainNavHeader"
                  linkVariant="grey_100"
                  fontWeight="font_600"
                  css={flexCenter}
                >
                  <a href="/pricing/" target="_blank" rel="noopener noreferrer">
                    <ChevronRight variant="grey_100" size="xs" />
                    Pricing
                    <NewWindowOpen className="rightIcon" variant="grey_100" size="xs" />
                  </a>
                </StyledDesc3>
                {/* <div className="sideBarNewsletterWrapper">
                  {isAliId && isLocalSideBarSubscribe ? (
                    <StyledDesc3>Thank you for subscribing to the Hasura Newsletter!</StyledDesc3>
                  ) : (
                    <>
                      <StyledDesc3 fontWeight="font_600" paddingBottom="pb12">
                        Sign up for Hasura Newsletter
                      </StyledDesc3>
                      <Paperform
                        onSubmitCB={onSubmitCB}
                        formId="hf-1079"
                        styleClass="marketoFormWrapper sideBarSubscribeWrapper"
                      />
                    </>
                  )}
                </div> */}
              </StyledSideBarFooter>
            ) : null}
          </div>
        </StyledSideBarWrapper>
        <StyledToggleSideNavWrapper onClick={() => setIsSubNavShow(!isSubNavShow)}>
          {isSubNavShow ? (
            <Close variant="white" size="md" />
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 2.25C2.58579 2.25 2.25 2.58579 2.25 3V21C2.25 21.4142 2.58579 21.75 3 21.75H9H21C21.4142 21.75 21.75 21.4142 21.75 21V3C21.75 2.58579 21.4142 2.25 21 2.25H9H3ZM9.75 3.75V20.25H20.25V3.75H9.75ZM8.25 3.75H3.75V20.25H8.25V3.75Z"
                fill="#FFF"
              />
            </svg>
          )}
        </StyledToggleSideNavWrapper>
        <StyledLearnWrapper>
          <div className={"learnAsideWrapper" + (toggleSideBar ? " learnAsideWrapperPos" : "")}>
            <div id="hero-banner" ref={heroBannerRef}>
              <HeroBanner />
            </div>
            {/* <div id="hasura-tutorials" ref={hasuraTutorialRef}>
              <Tutorials tutorialList={hasuraTutorialState} />
            </div> */}
            <div id="front-end-tutorials" ref={frontendTutorialRef}>
              <Tutorials tutorialList={frontendTutorialState} />
            </div>
            <div id="back-end-tutorials" ref={backendTutorialRef}>
              <Tutorials tutorialList={backendTutorialState} />
            </div>
            <div id="fullstack-tutorials" ref={fullstackTutorialRef}>
              <Tutorials tutorialList={fullstackTutorialState} />
            </div>
            <div id="database-tutorials" ref={databaseTutorialRef}>
              <Tutorials tutorialList={databaseTutorialState} />
            </div>
            <div id="intro-to-graphql" ref={introGraphQLRef}>
              <Tutorials tutorialList={introGraphQLState} />
            </div>
            <Contributor />
            <div id="learn-faq" ref={learnFaqRef}>
              <LearnFaq />
            </div>
            <div id="recommended-resources" ref={recommendedResourcesRef}>
              <RecommendedResources />
            </div>
            <UseHasuraFree />
            <div className="footerImg">
              <Image
                loading="lazy"
                src="https://graphql-engine-cdn.hasura.io/assets/main-site/footer-img.jpg"
                alt="footer illustration"
                width="976"
                height="533"
              />
            </div>
            <CopyWriterWithSidebar learnPage />
          </div>
        </StyledLearnWrapper>
      </div>
    </StyledMainWrapper>
  );
};

export default LearnIndex;
