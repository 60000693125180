import React from "react";
import styled from "styled-components";

import LearnSectionWrapper from "./LearnSectionWrapper";
import { StyledSubTitle2, StyledDesc2 } from "../shared/StyledTypography";
import { COLORS } from "../../globals/designSystem";
import { mq } from "../../globals/utils";

import NewWindowOpen from "../../globals/icons/NewWindowOpen";

const recommendedState = [
  {
    desc: "GraphQL fundamentals are fairly easy to learn. Recommended resources to help guide you through your Hasura & Fullstack development journey.",
    list: [
      {
        courseName: "React + GraphQL",
        courseLink: "/react-graphql/",
      },
      {
        courseName: "Vue + GraphQL",
        courseLink: "/vue-graphql/",
      },
      {
        courseName: "GraphQL Community",
        courseLink: "/graphql/#graphql-community",
      },
      {
        courseName: "Awesome Fluent GraphQL Clients",
        courseLink: "https://github.com/hasura/awesome-fluent-graphql",
      },
      {
        courseName: "Convert your Node js REST API to GraphQL API",
        courseLink: "https://hasura.io/blog/turn-your-node-js-rest-api-to-graphql/",
      },
      {
        courseName: "GraphQL FAQ",
        courseLink: "/graphql/#faqs",
      },
    ],
  },
  {
    desc: "We've put together a set of awesome lists for your favorite front-end frameworks. These lists talk about clients, developer tools, boilerplates, use cases, and more:",
    list: [
      {
        courseName: "awesome-react-graphql",
        courseLink: "https://github.com/hasura/awesome-react-graphql",
      },
      {
        courseName: "awesome-vue-graphql",
        courseLink: "https://github.com/hasura/awesome-vue-graphql",
      },
      {
        courseName: "awesome-angular-graphql",
        courseLink: "https://github.com/hasura/awesome-angular-graphql",
      },
    ],
  },
  // {
  //   desc: "Check out the sample apps below to get a taste of how real-time GraphQL and Auth work with Hasura. We also have boilerplates to help you get started quickly with your favorite framework.",
  //   list: [
  //     {
  //       courseName: "WhatsApp Clone",
  //       courseLink: "https://whatsapp-clone.demo.hasura.app/sign-in",
  //     },
  //     {
  //       courseName: "React to-do app",
  //       courseLink: "https://react-apollo-todo.demo.hasura.app/",
  //     },
  //     {
  //       courseName: "Uber-like app",
  //       courseLink: "https://realtime-location-tracking.demo.hasura.app/",
  //     },
  //   ],
  // },
];

const LearnSectionWrapperRemoveBor = styled(LearnSectionWrapper)`
  border-bottom: 0;
`;

const StyledRecommendWrapper = styled.div`
  .recommendedListWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 48px;
    padding-top: 32px;
    .recommendedList {
      ul {
        li {
          padding: 4px 0;
          ::marker {
            color: ${COLORS.sky_80};
          }
          a {
            display: flex;
            align-items: center;
            .arrow {
              margin-top: 2px;
              margin-left: 6px;
            }
          }
        }
      }
    }
  }
  ${mq.below.md} {
    .recommendedListWrapper {
      grid-template-columns: 1fr;
    }
  }
`;
const RecommendedResources = () => {
  const recommendedWrapper = recommendedState.map((recommendedItem, index) => (
    <div key={index} className="recommendedList">
      <StyledDesc2 paddingBottom="pb16">{recommendedItem.desc}</StyledDesc2>
      <StyledDesc2 className="introCourse">
        <ul className="discUl">
          {recommendedItem.list.map((item, index) => (
            <li key={index} className="disc">
              <a href={item.courseLink}>
                {item.courseName}
                <NewWindowOpen className="rightIcon" variant="sky_80" size="xs" />
              </a>
            </li>
          ))}
        </ul>
      </StyledDesc2>
    </div>
  ));
  return (
    <LearnSectionWrapperRemoveBor>
      <StyledRecommendWrapper>
        <StyledSubTitle2>Recommended GraphQL Resources</StyledSubTitle2>
        <div className="recommendedListWrapper">{recommendedWrapper}</div>
      </StyledRecommendWrapper>
    </LearnSectionWrapperRemoveBor>
  );
};

export default RecommendedResources;
