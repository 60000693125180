import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import LearnIndex from "../components/learn/LearnIndex";
import Seo from "../components/seo";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-learn.png",
};

const canonicalUrl = "https://hasura.io/learn/";

const faqSchema = [
  {
    "@type": "Question",
    name: "Are these tutorials free?",
    acceptedAnswer: {
      "@type": "Answer",
      text: "Absolutely! All of our GraphQL Tutorials are free and open source.",
    },
  },
  {
    "@type": "Question",
    name: "Who can take these tutorials?",
    acceptedAnswer: {
      "@type": "Answer",
      text: "All frontend / backend / fullstack developers can take these tutorial series to build real-world applications.",
    },
  },
  {
    "@type": "Question",
    name: "Will there be example code snippets or links to documentation?",
    acceptedAnswer: {
      "@type": "Answer",
      text: "All the tutorials will have the full source code available in the open-source hasura/learn-graphql repo.",
    },
  },
  {
    "@type": "Question",
    name: "Is GraphQL easy to learn?",
    acceptedAnswer: {
      "@type": "Answer",
      text: "Yes! GraphQL is easy to learn and consume on the client. However, building a custom GraphQL Server following best practices can be difficult for developers coming from REST API background.",
    },
  },
  {
    "@type": "Question",
    name: "What front end clients are being used in the tutorials?",
    acceptedAnswer: {
      "@type": "Answer",
      text: "Most of the frontend frameworks use Apollo Client for the integration. We are planning to add more tutorials around other clients like Urql.",
    },
  },
  {
    "@type": "Question",
    name: "What technologies are used in the tutorials?",
    acceptedAnswer: {
      "@type": "Answer",
      text: "Technology choices are mostly wide open. The javascript libraries used can be replaced with an equivalent one. The server-side APIs are taken care of by Hasura GraphQL Cloud Service connected to a PostgreSQL database.",
    },
  },
  {
    "@type": "Question",
    name: "Is GraphQL frontend or backend?",
    acceptedAnswer: {
      "@type": "Answer",
      text: "GraphQL is just a query language spec and there are implementations on both client and server.",
    },
  },
  {
    "@type": "Question",
    name: "What language is GraphQL written in?",
    acceptedAnswer: {
      "@type": "Answer",
      text: "GraphQL is language agnostic. Reference implementations for the spec have been written in multiple languages, the most popular one being in javascript.",
    },
  },
];
const Learn = props => (
  <Layout location={props.location}>
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org/",
          "@type": "FAQPage",
          mainEntity: faqSchema,
        })}
      </script>
    </Helmet>
    <Seo
      title="Fullstack GraphQL Tutorial Series | Learn GraphQL & Hasura"
      description="Learn frontend & backend GraphQL concepts from basic to advanced in our GraphQL Tutorial Series in top frontend frameworks using Hasura Backend API."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <LearnIndex location={props.location} />
  </Layout>
);

export default Learn;
