import React, { useState } from "react";
import styled from "styled-components";

import ArrowRight from "../../globals/icons/ArrowRight";
import LearnSectionWrapper from "./LearnSectionWrapper";
import StyledFaqWrapper from "../shared/StyledFaqWrapper";
import { StyledSubTitle2, StyledDesc2 } from "../shared/StyledTypography";
import { COLORS, ELEVATION } from "../../globals/designSystem";
import { mq } from "../../globals/utils";
import { transition } from "../shared/CommonStyled";

import github from "./images/github.svg";
import discord from "./images/discord.svg";

const faqAllState = [
  {
    id: "are-these-tutorials-free",
    question: "Are these tutorials free?",
    answer: <StyledDesc2>
      Absolutely! All of our GraphQL Tutorials are free and open source.
    </StyledDesc2>,
  },
  {
    id: "who-can-take-these-tutorials",
    question: "Who can take these tutorials?",
    answer: <StyledDesc2>
      All frontend / backend / fullstack developers can take these tutorial series to build real-world applications.
    </StyledDesc2>,
  },
  {
    id: "will-there-be-example-code-snippets-or-links-to-documentation",
    question: "Will there be example code snippets or links to documentation?",
    answer: <StyledDesc2>
      All the tutorials will have the full source code available in the open-source <a href="https://github.com/hasura/learn-graphql">hasura/learn-graphql</a> repo.
    </StyledDesc2>,
  },
  {
    id: "graphql-easy-to-learn",
    question: "Is GraphQL easy to learn?",
    answer: <StyledDesc2>
      Yes! GraphQL is easy to learn and consume on the client. However, building a custom GraphQL Server following best practices can be difficult for developers coming from REST API background.
    </StyledDesc2>,
  },
  {
    id: "what-front-end-clients-are-being-used-in-the-tutorials",
    question: "What front end clients are being used in the tutorials?",
    answer: <StyledDesc2>
      Most of the frontend frameworks use Apollo Client for the integration. We are planning to add more tutorials around other clients like Urql.
    </StyledDesc2>,
  },
  {
    id: "what-technologies-are-used-in-the-tutorials",
    question: "What technologies are used in the tutorials?",
    answer: <StyledDesc2>
      Technology choices are mostly wide open. The javascript libraries used can be replaced with an equivalent one. The server-side APIs are taken care of by Hasura GraphQL Cloud Service connected to a PostgreSQL database.
    </StyledDesc2>,
  },
  {
    id: "is-graphql-frontend-or-backend",
    question: "Is GraphQL frontend or backend?",
    answer: <StyledDesc2>
      GraphQL is just a query language spec and there are implementations on both client and server.
    </StyledDesc2>,
  },
  {
    id: "what-language-is-graphql-written-in",
    question: "What language is GraphQL written in?",
    answer: <StyledDesc2>
      GraphQL is language agnostic. Reference implementations for the spec have been written in multiple languages, the most popular one being in javascript.
    </StyledDesc2>,
  },
]

const channelState = [
  {
    imgSrc: github,
    title: "Hasura Github Channel",
    desc: "Join our GitHub channel and stay connected with Hasura users from over 83 countries.",
    link: "https://github.com/hasura/",
  },
  {
    imgSrc: discord,
    title: "Hasura Discord Channel",
    desc: "Join our Discord channel and stay connected with Hasura users from over 83 countries.",
    link: "https://discord.com/invite/hasura/",
  },
]

const StyledCardWrapper =styled.div`
  max-width: 688px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  padding-top: 16px;
  .cardList {
    background-color: ${COLORS.white};
    box-shadow: ${ELEVATION.l_0};
    padding: 24px;
    border-radius: 4px;
    ${transition}
    &:hover {
      box-shadow: ${ELEVATION.l_2};
      .displayFlex {
        .arrow {
          transform: translateX(5px);
        }
      }
    }
    .displayFlex {
      display: flex;
      align-items: center;
      padding-bottom: 16px;
      .cardImg {
        padding-right: 16px;
        img {
          width: 32px;
        }
      }
    }
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
  }
  ${mq.above.xxxl} {
    grid-template-columns: 1fr;
    align-self: flex-start;
  }
`;

const LearnFaq = ({tutorialList}) => {
  const [openFaqIds, setOpenFaqIds] = useState({});
  const showAnswer = (id) => {
    const newOpenFaqIds = {...openFaqIds};
    if (typeof newOpenFaqIds[id] === "undefined") {
      newOpenFaqIds[id] = true;
    } else {
      newOpenFaqIds[id] = !newOpenFaqIds[id];
    }
    setOpenFaqIds(newOpenFaqIds);
  };
  const ListFaq = ({faqItem}) => {
    return (
      <div className="faqListWrapper faqListWrapperLearn">
        <StyledDesc2
          role="button"
          tabIndex="0"
          onClick={() => showAnswer(faqItem.id)}
          variant="sky_90"
          className="faqQuestion"
        >
          <span>
            {faqItem.question}
          </span>
          <svg className={(openFaqIds[faqItem.id]) ? "rotateImg" : ""} width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.8187 13.9348L17.9987 19.7548L12.1787 13.9348C11.5937 13.3498 10.6487 13.3498 10.0637 13.9348C9.47875 14.5198 9.47875 15.4648 10.0637 16.0498L16.9488 22.9348C17.5338 23.5198 18.4788 23.5198 19.0638 22.9348L25.9487 16.0498C26.5337 15.4648 26.5337 14.5198 25.9487 13.9348C25.3637 13.3648 24.4037 13.3498 23.8187 13.9348Z" fill="#005C8F"/>
          </svg>
        </StyledDesc2>
        <div className={"faqAnswer" + ((openFaqIds[faqItem.id]) ? " showAnswer" : " hideAnswer")}>
          {faqItem.answer}
        </div>
      </div>
    )
  }
  const faqAllDetails = faqAllState.map((faqItem, index) => {
    return(
      <ListFaq key={index} faqItem={faqItem}/>
    )
  });

  const channelWrapper = channelState.map((item, index) => (
    <a key={index} className="cardList" href={item.link}>
      <div className="displayFlex">
        <div className="cardImg">
          <img src={item.imgSrc} alt={item.title} />
        </div>
        <StyledDesc2 fontWeight="font_600">{item.title}<ArrowRight variant="grey_100" size="xs" className="arrow"/></StyledDesc2>
      </div>
      <StyledDesc2>{item.desc}</StyledDesc2>
    </a>
  ))
  return (
    <LearnSectionWrapper>
      <StyledFaqWrapper>
        <StyledSubTitle2 paddingBottom="pb16" variant="grey_100">Frequently Asked Questions</StyledSubTitle2>
        <div className="faqWrapperLearn">
          <div>
            {faqAllDetails}
          </div>
          <StyledCardWrapper>
            {channelWrapper}
          </StyledCardWrapper>
        </div>
      </StyledFaqWrapper>
    </LearnSectionWrapper>
  );
};

export default LearnFaq;
