import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import StyledPromoBanner from "./StyledPromoBanner";
import ArrowRight from "../../globals/icons/ArrowRight";

import { Image } from "../../globals/UIKit";
import { StyledSubTitle2, StyledDescTag } from "../shared/StyledTypography";
import { mq } from "../../globals/utils";
import { isBrowser } from "../../utils/constants";

// import contributorImg from "./images/contributor-img.png";

const StyledPromoBannerImg = styled(StyledPromoBanner)`
  background-image: ${props =>
    props.isVisible
      ? "url(https://res.cloudinary.com/dh8fp23nd/image/upload/v1672906143/main-web/contributor-img-035da8b4f3bc13df1b26b46a6f5ea74c_keziox.png)"
      : ""};

  ${mq.below.md} {
    background-image: none;
  }
`;

const Contributor = () => {
  const [isVisible, setIsVisible] = useState(false);

  const githubIllusRef = useRef();

  useEffect(() => {
    if (isBrowser) {
      if (!githubIllusRef?.current) {
        return;
      }

      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });

      observer.observe(githubIllusRef.current);
    }
  }, [githubIllusRef]);

  return (
    <StyledPromoBannerImg isVisible={isVisible || false}>
      <div className="p40" ref={githubIllusRef}>
        <StyledSubTitle2 className="wd50" paddingBottom="pb24">
          Hasura Learn is fully open source and maintained by our amazing community
        </StyledSubTitle2>
        <div className="promoDisplayFlex">
          <StyledSubTitle2>150+ contributors</StyledSubTitle2>
          <div className="divider"></div>
          <StyledSubTitle2>700+ Stars</StyledSubTitle2>
        </div>
        <StyledDescTag className="contributeLink" linkVariant="grey_100" fontWeight="font_600">
          <a
            href="https://github.com/hasura/learn-graphql/blob/master/CONTRIBUTING.md"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contribute
            <div className="arrow">
              <ArrowRight variant="grey_100" size="xs" className="arrow" />
            </div>
          </a>
        </StyledDescTag>
      </div>
      <div className="promoImgWrapper">
        <Image
          className="showMobile"
          src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1672906143/main-web/contributor-img-035da8b4f3bc13df1b26b46a6f5ea74c_keziox.png"
          alt="Promo"
        />
      </div>
    </StyledPromoBannerImg>
  );
};

export default Contributor;
